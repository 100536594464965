/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-17 15:01:49
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-17 15:04:32
 */
import ActivityAdd from './ActivityAdd.vue'
export default ActivityAdd
