/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-17 15:01:42
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-25 22:17:58
 */
import ActivityForm from './ActivityForm'
import ActivitySuccess from './ActivitySuccess'
export default {
  name: 'ActivityAdd',
  components: {
    ActivityForm,
    ActivitySuccess
  },
  data () {
    return {
      isUnMonitor: false
    }
  },
  computed: {
    isAdd () {
      if (/add/.test(this.$route.path)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    async success (val) {
      await this.$refs.activeSuccess.updateForm(val)
      this.$refs.activeSuccess.show()
    },
    monitorChange (val) {
      if (val === 4) {
        this.isUnMonitor = true
      } else {
        this.isUnMonitor = false
      }
    }
  }
}
